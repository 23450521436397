import { registerLocaleData } from '@angular/common';
import { LOCALE_LOAD_MAPPING } from 'common';
/**
 * https://stackoverflow.com/questions/61035621/how-do-i-dynamically-import-locales-in-angular-9
 * https://medium.com/angular-in-depth/dynamic-import-of-locales-in-angular-b994d3c07197
 * https://stackoverflow.com/questions/69970566/module-not-found-error-package-path-locales-is-not-exported-from-package-aft
 */
export class LocaleLoader {
  public static load(locale: string): Promise<any> {
    // register locale for pipes

    let mappedLocal = locale;

    if (locale in LOCALE_LOAD_MAPPING) {
      mappedLocal = LOCALE_LOAD_MAPPING[locale];
    }

    //Angular a migré de webpack a esbuild et il y a des problèmes avec les imports dynamiques
    //https://github.com/angular/angular-cli/issues/26904
    //https://github.com/evanw/esbuild/issues/700
    //https://github.com/evanw/esbuild/pull/2508
    const languages = {
      fr: import('../../../../node_modules/@angular/common/locales/fr.mjs'),
      ar: import('../../../../node_modules/@angular/common/locales/ar.mjs'),
      bg: import('../../../../node_modules/@angular/common/locales/bg.mjs'),
      hr: import('../../../../node_modules/@angular/common/locales/hr.mjs'),
      cs: import('../../../../node_modules/@angular/common/locales/cs.mjs'),
      da: import('../../../../node_modules/@angular/common/locales/da.mjs'),
      en: import('../../../../node_modules/@angular/common/locales/en.mjs'),
      'en-CA': import('../../../../node_modules/@angular/common/locales/en-CA.mjs'),
      'en-SG': import('../../../../node_modules/@angular/common/locales/en-SG.mjs'),
      fi: import('../../../../node_modules/@angular/common/locales/fi.mjs'),
      de: import('../../../../node_modules/@angular/common/locales/de.mjs'),
      'de-CH': import('../../../../node_modules/@angular/common/locales/de-CH.mjs'),
      el: import('../../../../node_modules/@angular/common/locales/el.mjs'),
      he: import('../../../../node_modules/@angular/common/locales/he.mjs'),
      hu: import('../../../../node_modules/@angular/common/locales/hu.mjs'),
      nl: import('../../../../node_modules/@angular/common/locales/nl.mjs'),
      it: import('../../../../node_modules/@angular/common/locales/it.mjs'),
      es: import('../../../../node_modules/@angular/common/locales/es.mjs'),
      ja: import('../../../../node_modules/@angular/common/locales/ja.mjs'),
      ko: import('../../../../node_modules/@angular/common/locales/ko.mjs'),
      nb: import('../../../../node_modules/@angular/common/locales/nb.mjs'),
      pl: import('../../../../node_modules/@angular/common/locales/pl.mjs'),
      'pt-PT': import('../../../../node_modules/@angular/common/locales/pt-PT.mjs'),
      ro: import('../../../../node_modules/@angular/common/locales/ro.mjs'),
      ru: import('../../../../node_modules/@angular/common/locales/ru.mjs'),
      sr: import('../../../../node_modules/@angular/common/locales/sr.mjs'),
      sk: import('../../../../node_modules/@angular/common/locales/sk.mjs'),
      sl: import('../../../../node_modules/@angular/common/locales/sl.mjs'),
      sv: import('../../../../node_modules/@angular/common/locales/sv.mjs'),
      'es-MX': import('../../../../node_modules/@angular/common/locales/es-MX.mjs'),
      'es-AR': import('../../../../node_modules/@angular/common/locales/es-AR.mjs'),
      th: import('../../../../node_modules/@angular/common/locales/th.mjs'),
      tk: import('../../../../node_modules/@angular/common/locales/tk.mjs'),
      tr: import('../../../../node_modules/@angular/common/locales/tr.mjs'),
      uk: import('../../../../node_modules/@angular/common/locales/uk.mjs'),
      vi: import('../../../../node_modules/@angular/common/locales/vi.mjs'),
      zh: import('../../../../node_modules/@angular/common/locales/zh.mjs'),
      'zh-Hans-HK': import('../../../../node_modules/@angular/common/locales/zh-Hans-HK.mjs'),
      'zh-Hans-SG': import('../../../../node_modules/@angular/common/locales/zh-Hans-SG.mjs'),
    };

    if (!(mappedLocal in languages)) {
      console.error('Translation file ' + mappedLocal + ' mapped from ' + locale + ' not found in LocaleLoader for Angular pipes.');
      mappedLocal = 'en';
    }

    /*import(
      /* webpackInclude: /^(el|he|hu|nl|it|es|ja|ko|nb|pl|pt-PT|ro|ru|sr|sk|sl|sv|es-MX|es-AR|th|tk|uk|vi|zh|zh-Hans-HK|zh-Hans-SG)\.mjs/ */
    /* webpackMode: "lazy-once" */
    /* webpackChunkName: "i18n-base" */
    /* `@../../../../node_modules/@angular/common/locales/${mappedLocal}.mjs`
    )*/
    return languages[mappedLocal]
      .then((loadedLocale) => {
        if (mappedLocal !== locale) registerLocaleData(loadedLocale.default, mappedLocal);
        registerLocaleData(loadedLocale.default, locale);
        console.log('Registered locale ', loadedLocale.default[0]);
        return loadedLocale;
      })
      .catch((err) => {
        console.error('Translation file ' + mappedLocal + ' mapped from ' + locale + ' not found for Angular pipes.');
        return err;
      });
  }
}
