import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hiji-translate-texts',
  templateUrl: './translate-texts.component.html',
  styleUrls: ['./translate-texts.component.scss'],
})
export class TranslateTextsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
